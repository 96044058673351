import React, {useEffect} from 'react';
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

function ConfidentialPolicyScreen({setIsOpen}) {

    useEffect(() => {
        setIsOpen(false)
        window.scrollTo({top: 0, left: 0, behavior: 'instant'})
    }, []);


    return (
        <section id={'confidentialPolicyScreen'}>
            <SectionHeader/>

            <div className="section-container">
                <h1>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h1>

                <p>В нашей Политике конфиденциальности ниже подробно описано, как NCC осуществляет сбор, управление и защиту всей конфиденциальной информации о пользователях.</p>

                <h2>ВВЕДЕНИЕ</h2>

                <p>У нас очень важна конфиденциальность. Мы стремимся защищать и уважать ваши личные данные. В настоящей Политике конфиденциальности описывается, какие типы персональных данных мы собираем о вас, когда вы решите воспользоваться нашими услугами, как мы будем использовать ваши персональные данные, когда и кому мы их передаем и как мы обеспечиваем их безопасность.
                    В нем также подробно описаны ваши права в отношении обработки нами вашей личной
                    информации и способы их реализации. Пожалуйста, найдите время, чтобы прочитать и понять эту политику.
                </p>

                <p>Время от времени мы можем вносить изменения в это Уведомление, и важно, чтобы вы проверяли это
                    Уведомление на наличие обновлений. Любая личная информация, которую мы храним, будет регулироваться
                    текущим уведомлением о конфиденциальности на данный момент. Если мы внесем изменения, которые мы
                    считаем важными, мы сообщим вам о них.</p><p>Обратите внимание, что это уведомление адресовано
                клиентам и потенциальным клиентам. Если вы являетесь сотрудником NCC подрядчиком NCC или сторонним
                поставщиком услуг, ваша личная информация будет использоваться в связи с вашим трудовым договором,
                вашими договорными отношениями или в соответствии с нашими отдельными политиками, с которыми можно
                ознакомиться, связавшись с нами.</p><p>Настоящая Политика конфиденциальности (далее «Политика»)
                объясняет, каким образом NCC (Компания, мы) собирает, использует, хранит и раскрывает информацию о
                пользователях (Клиент, вы), полученную через https://personal.onlinecentrfst.com/ (Веб-сайт). Компания придерживается
                принципов,
                содержащихся в Законе о защите данных 1998 года и Директиве ЕС об электронной конфиденциальности.
                </p><p>При доступе к нашим веб-сайтам, в том числе при использовании любого из каналов связи для связи с
                нами, мы считаем, что вы прочитали и поняли условия этого уведомления и то, как мы
                обрабатываем любую информацию, которую вы раскрываете нам, включая личные данные, до того, как стать
                клиентом. После того, как вы откроете у нас учетную запись, вы соглашаетесь с тем, что это уведомление,
                включая любые поправки, будет регулировать то, как мы собираем, храним, используем, передаем и в любой
                другой форме обрабатываем ваши личные данные, и ваши права во время наших деловых отношений и после их
                прекращения.
                </p><p>Используя этот Веб-сайт, вы соглашаетесь с методами обработки данных, описанными в настоящей Политике. Компания может время от времени пересматривать настоящую Политику. Если в настоящую Политику конфиденциальности будут внесены изменения, эти изменения будут опубликованы на Веб-сайте и вступят в силу немедленно, если не указано иное. Любая имеющаяся информация будет регулироваться самой последней Политикой.</p>

                <h2>КАКУЮ ИНФОРМАЦИЮ КОМПАНИЯ СОХРАНЯЕТ</h2>

                <p>Вы предоставляете нам свою личную информацию в процессе подачи заявки на учетную запись, которую мы
                    обязаны сохранить. Мы также храним информацию о вашей торговой деятельности,
                    использовании веб-сайта и политике использования файлов cookie (см. ниже). Когда вы посещаете наш
                    веб-сайт или пользуетесь нашими услугами, мы также можем регистрировать ваш IP-адрес, уникальный
                    идентификатор вашего компьютера или другого устройства доступа. В целях обеспечения качества и
                    контроля мы можем отслеживать и записывать общение с вами. Как правило, эта информация получается,
                    когда вы соглашаетесь зарегистрироваться в качестве клиента

                    и пользоваться нашими услугами. Настройки разрешений на использование файлов cookie в вашем
                    веб-браузере подразумевают согласие с нашей политикой использования файлов cookie.
                </p><p>В рамках нашей деятельности мы собираем персональные данные клиентов и потенциальных клиентов, в
                том числе:</p>
                <ul>
                    <li>Имя, Фамилия и контактные данные</li>
                    <li>Дата рождения и пол</li>
                    <li>Информация о ваших доходах и состоянии, включая сведения о ваших активах и обязательствах,
                        остатках на счетах, торговых отчетах, налоговых и финансовых отчетах.
                    </li>
                    <li>Сведения о профессии и занятости</li>
                    <li>Данные о местоположении</li>
                    <li>Знания и опыт в трейдинге, толерантность к риску и профиль риска</li>
                    <li>IP-адрес, характеристики устройства и другая информация, касающаяся вашей торговой
                        деятельности.
                    </li>
                    <li>Банковский счет, электронные кошельки и данные кредитной карты</li>
                    <li>Подробная информация о ваших посещениях нашего веб-сайта или наших приложений, включая, помимо прочего, данные о трафике, данные о местоположении и другие данные связи.
                    </li>
                </ul>
                
                <p>Мы используем файлы cookie для хранения и сбора информации об использовании вами нашего
                веб-сайта. Файлы cookie — это небольшие текстовые файлы, сохраняемые браузером на жестком диске вашего
                оборудования. Они отправляют информацию, хранящуюся на них, обратно на наш веб-сервер, когда вы заходите
                на наш Веб-сайт. Эти файлы cookie позволяют нам устанавливать личные настройки и загружать ваши личные
                предпочтения, чтобы улучшить ваш опыт. Вы можете узнать больше о наших файлах cookie в нашей Политике
                использования файлов cookie, доступной на нашем веб-сайте.</p><p>Мы также храним записи о вашем торговом
                поведении, в том числе записи:</p>
                <ul>
                    <li>Продукты, которыми вы торгуете с нами</li>
                    <li>Исторические данные о сделанных вами сделках и инвестициях, включая вложенную сумму.</li>
                    <li>Ваши предпочтения в отношении определенных видов продуктов и услуг</li>
                </ul>
                
                <p>По закону мы обязаны идентифицировать вас, если вы открываете новую
                учетную запись или добавляете новое подписавшееся лицо к существующей учетной записи. Законы о борьбе с
                отмыванием денег требуют от нас просмотра и записи сведений об определенных документах
                (например, фотографических и нефотографических документах) в соответствии со стандартами, установленными
                в соответствии с этими законами. Документация, удостоверяющая личность, в соответствии с требованиями
                законодательства о борьбе с отмыванием денег или другого законодательства, относящегося к услугам,
                которые мы вам предоставляем, включает:
                </p>
                <ul>
                    <li>(а) паспорт;</li>
                    <li>(б) водительские права;</li>
                    <li>(в) национальное удостоверение личности (при наличии);</li>
                    <li>(г) счета за коммунальные услуги;</li>
                    <li>(д) договор о доверительном управлении (если применимо);</li>
                    <li>(е) проверка кредитоспособности физического лица;</li>
                    <li>(ё) другую информацию, которую мы считаем необходимой для выполнения наших функций и деятельности.</li>
                </ul>
                
                <p>Если вы являетесь корпоративным клиентом, мы должны собрать дополнительную информацию, такую ,как
                корпоративные документы с адресом, акционерами, директорами, должностными лицами, включая дополнительную
                личную информацию об акционерах и директорах. Мы имеем право запросить любую дополнительную информацию,
                которую сочтем необходимой для соблюдения наших законодательных и нормативных требований.</p><p>Мы
                получаем эту информацию несколькими способами через использование вами наших услуг и веб-сайтов,
                приложения для открытия счета, наши демо-формы регистрации, формы регистрации на вебинар, веб-сайт файлы
                cookie и аналогичные технологии отслеживания, встроенные в наши веб- сайты и приложения, подписка на
                обновления новостей и информацию, предоставляемую в ходе наших текущих отношений</p>
                <p>Мы также можем собирать эту информацию о вас от третьих лиц либо через купленные сторонние
                    маркетинговые списки, общедоступные источники, платформы социальных сетей, представляющих брокеров и
                    аффилированных лиц, банкиров и процессоров кредитных карт, основанные на подписке аналитические базы
                    данных и другие сторонние партнеры.</p>
                <p>Время от времени мы можем добровольно запрашивать другую личную информацию (например, посредством
                    маркетинговых исследований, опросов или специальных предложений). Если вы решите не предоставлять
                    информацию, необходимую нам для выполнения вашего запроса на конкретный продукт или услугу, мы не
                    сможем предоставить вам запрошенный продукт или услугу.</p><p>Мы можем записывать любые сообщения, электронные, по телефону, лично или иным образом, которые мы имеем с вами в отношении услуг, которые мы вам предоставляем, и наших отношений с вами. Эти записи будут нашей исключительной собственностью и будут служить доказательством общения между нами. Такие телефонные разговоры могут быть записаны без использования предупредительного сигнала или любого другого дополнительного уведомления.</p>

                <h2>КАК МЫ ЭТО ИСПОЛЬЗУЕМ?</h2>

                <p>Мы используем информацию о вас следующими способами:</p>

                <p>Мы используем ваши личные данные и информацию, полученную из других источников, с целью выполнения нормативных и правовых требований, для маркетинга, администрирования и
                    обслуживания клиентов, чтобы гарантировать, что контент и услуги, которые мы предлагаем, соответствуют вашим потребностям и интересам. Мы храним вашу информацию в течение разумного периода для этих целей. Возможно, нам потребуется поделиться вашей информацией с нашими поставщиками услуг и агентами. Как правило, мы требуем, чтобы сторонние организации, которые обрабатывают или получают личную информацию в качестве поставщиков услуг, признавали ее конфиденциальность и обязывались уважать право человека на неприкосновенность частной жизни и соблюдать принципы защиты данных, включая настоящую Политику.
                </p>

                <p>Мы ,или агенты, действующие от нашего имени, можем:</p>
                <ul>
                    <li>выполнять проверки личности, проверки попыток отмывания денег и предотвращения мошенничества.
                    </li>
                    <li>Передать организациям (включая правоохранительные органы), занимающимся предотвращением и
                        обнаружением мошенничества, которые могут использовать вашу информацию таким же образом;
                    </li>
                    <li>изучить вашу кредитоспособность ,и при этом можем связаться с банками, финансовыми учреждениями и бюро кредитных историй;</li>
                </ul>

                <p>Мы можем использовать информацию, которую вы нам предоставляете, для отправки вам SMS- сообщений или
                    сообщений электронной почты для просмотра, включая, помимо прочего, маржинальные требования или
                    другую информацию.</p><p>Предоставляя нам свою личную информацию, вы даете согласие на обработку нами ваших личных данных для вышеуказанных целей.</p>

                <h2>ИСПОЛЬЗОВАНИЕ И УПРАВЛЕНИЕ ФАЙЛАМИ COOKIES</h2>

                <p>Веб-сайты NCC используют файлы cookie для предоставления функций, необходимых для
                    корректного просмотра нашего сайта. Веб-сайты NCC создают файлы cookie при посещении наших
                    веб-сайтов, если только пользователь не изменил настройки файлов cookie в своем браузере, чтобы
                    отказаться от файлов cookie. Обратите внимание, что при отключении файлов cookie многие разделы
                    нашего веб-сайта и услуги не будут доступны для вас; таких как NCC где функция входа в систему будет
                    недоступна. Кроме того, при отключении файлов cookie функции социальных закладок не будут работать.
                </p><p>NCC не использует файлы cookie для сбора личной идентификационной информации о вас. Файлы cookie,
                которые мы используем, собирают исключительно анонимную информацию, которая используется для оптимизации
                наших услуг.</p><p>Обратите внимание, что не все файлы cookie на наших сайтах устанавливаются нами. См.
                раздел ниже о сторонних файлах cookie.</p><p>Мы получаем информацию о вас посредством доступа к файлам
                cookie (текстовые файлы, которые хранятся на вашем компьютере или другом устройстве доступа), когда вы
                посещаете наш веб-сайт или пользуетесь нашими услугами. Файлы cookie хранят небольшие фрагменты
                информации, такие как имена и адреса электронной почты. Мы можем сделать это с помощью веб-маяков (также
                известных как чистые GIF-файлы или веб-маяки). Веб-маяки не хранят дополнительную
                информацию на вашем компьютере, но, взаимодействуя с нашими файлами cookie на вашем компьютере, они
                могут запоминать информацию о нашем веб-сайте, которую мы используем для улучшения вашего
                пользовательского опыта. Мы используем функциональные файлы cookie для анализа того, как посетители
                используют наш веб-сайт, а также для отслеживания производительности и функционирования веб-сайта. Это
                позволяет нам обеспечивать высокое качество обслуживания клиентов, быстро выявляя и устраняя любые
                возникающие проблемы.
                Например, мы можем использовать файлы cookie производительности, чтобы отслеживать, какие страницы
                наиболее популярны и какой метод связи между страницами веб-сайта наиболее
                эффективен.
                </p><p>Функциональные файлы cookie используются Торговой платформой, чтобы мы могли запоминать ваши
                предпочтения и идентифицировать вас как пользователя, обеспечивать безопасность вашей информации и
                работать более надежно и эффективно. Например, файлы cookie избавляют вас от необходимости каждый раз
                вводить имя пользователя.</p><p>получить доступ к Торговой платформе и вспомнить свои предпочтения,
                например, какой язык вы хотите видеть при входе в систему.</p><p>Вы можете удалить файлы cookie в любое
                время, используя настройки своего веб-браузера. Вы также можете использовать свой веб-браузер для
                отключения файлов cookie, хотя этот и другие веб- сайты могут работать неправильно, если вы сделаете
                это, и вы не сможете войти в систему.
                Дополнительная информация об удалении файлов cookie или управлении ими доступна на сайте
                www.AboutCookies.org.
                </p><p>Этот веб-сайт может использовать Google Analytics, службу веб-аналитики, предоставляемую Google,
                Inc.</p><p>(Google). Google Analytics использует аналитические файлы cookie, размещенные на вашем компьютере, чтобы помочь веб-сайту проанализировать использование сайта пользователями. Информация, сгенерированная файлом cookie об использовании вами веб-сайта (включая ваш IP- адрес), может быть передана и сохранена Google на их серверах. Google может использовать эту информацию для оценки использования вами веб-сайта, составления отчетов о деятельности веб- сайта и предоставления других услуг, связанных с деятельностью веб-сайта и использованием Интернета. Google также может передавать эту информацию третьим лицам, если это требуется по закону, или когда такие третьи лица обрабатывают информацию от имени Google. Google не будет связывать ваш IP-адрес с какими-либо другими данными. Используя этот веб-сайт, вы даете
                согласие на обработку данных о вас компанией Google в порядке и для целей, указанных выше.
                </p>

                <h2>ТИПЫ COOKIE САЙТА NCC</h2>

                <ul>
                    <li>• Файлы cookie сеанса сохраняют учетные данные сеанса веб-сайта для посетителей, как вошедших,
                        так и не вошедших в систему. После закрытия браузера файл cookie
                        деактивируется, а сеанс закрывается.
                    </li>
                    <li>• Аналитические файлы cookie собирают информацию о посетителях, такую ,как количество посещений
                        и то, как наш веб-сайт был найден (например, посредством маркетинговой
                        кампании). Эта информация чрезвычайно важна для нас, поскольку она помогает нам улучшать наш
                        веб-сайт и повышать качество обслуживания посетителей.
                    </li>
                    <li>• Функциональные файлы cookie предназначены исключительно для удобства посетителей на веб-сайте.
                        Например, они распознают открытый/закрытый статус всплывающих сообщений, запоминают часовой пояс
                        или состояние просмотра Экономического календаря и предоставляют доступ к информации,
                        сохраненной при регистрации в NCC Без этих
                        функциональных файлов cookie никакая информация о настройках не может быть сохранена.
                    </li>
                    <li>•	Сторонние файлы cookie устанавливаются другими веб-сайтами, которые размещают файлы cookie на компьютере пользователя, и вводятся, когда вы входите в их службу. Эти файлы
                        cookie являются сеансовыми, аналитическими и функциональными, и NCC не контролирует их распространение. Эти файлы cookie определяют, вошел ли пользователь в социальную
                        сеть, управляют созданием закладок или обменом контентом NCC а также
                        определяют, когда пользователь просматривает видео NCC если у вас установлен Flash player; NCC использует Adobe Flash Player для отображения этого контента. Флэш-куки (локальные общие объекты) используются для проверки того, какой браузер пользователя поддерживает Flash. Если вы хотите отключить флэш-куки, посетите
                        www.adobe.com/products/flashplayer/security. С другими третьими сторонами следует связаться напрямую, чтобы просмотреть их информацию о конфиденциальности файлов cookie.
                    </li>
                </ul>

                <p>Для получения дополнительной информации о файлах cookie и их использовании посетите: https://ico.org.uk/global/cookies/</p>

                <h2>КАК МЫ ЗАЩИЩАЕМ ВАШУ ИНФОРМАЦИЮ? КОГДА И КАК ОБРАЩАТЬСЯ К НАМ</h2>

                <p>Если ваши личные данные изменились, если у вас есть какие-либо вопросы о том, как мы используем вашу информацию, или вы хотите помочь нам улучшить эту Политику, сообщите нам об этом, связавшись с нами по адресу: clearncentr@nkc.expert</p>
            </div>
        </section>
    );
}

export default ConfidentialPolicyScreen;